import styled from "styled-components";
import NextHeadSeo from "next-head-seo";
import Cookies from "js-cookie";
import dynamic from "next/dynamic";

import { useConfigContext } from "~/providers/ConfigContextProvider";
import { AppProps } from "~/types";
import { Menu } from "../app/Menu";
import { Footer } from "../app/Footer";
import { UserTracking } from "../app/UserTracking";
import { useEffect, useState } from "react";
import { NewsletterPopup } from "../app/NewsletterPopup";

const Login = dynamic(() => import("../app/Login"));

const FullHeightGrid = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  justify-items: stretch;
`;

export const LayoutMain = ({ children, props }: AppProps) => {
  const config = useConfigContext();

  const [isAuthenticated, setAuthenticated] = useState<undefined | boolean>(
    undefined
  );

  const cookieName = `pwd-${props?.data?.slug}`;

  useEffect(() => {
    try {
      setAuthenticated(Cookies.get(cookieName) === "yes");
    } catch (e) {
      setAuthenticated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props?.data?.password?.trim() && !isAuthenticated)
    return <Login cookieName={cookieName} password={props?.data?.password?.trim()} />;

  return (
    <>
      <NextHeadSeo
        title={`${config.appTitle}`}
        og={{
          image: `${config.baseUrl}/img/ikon-poster.jpg`,
          type: "article",
          siteName: `${config.appTitle}`,
        }}
        twitter={{
          card: "summary_large_image",
        }}
      />
      <div id="test" style={{"display":"none"}}>test</div>
      <UserTracking />
      <NewsletterPopup />
      <FullHeightGrid>
        {children}

        <Footer />
      </FullHeightGrid>
      <Menu />
      
    </>
  );
};
export default LayoutMain;
